<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Tambah General Jurnal" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">No. Jurnal *</label>
            <vs-input class="w-full" v-model="data.no_jurnal" placeholder="Generate Otomatis" disabled/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Proyek *</label>
            <v-select :options="proyeks" label="nama" :reduce="item => item.id" @input="onChangeProyek" :clearable="false" v-model="data.id_proyek"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Journal Date *</label>
            <flat-pickr class="w-full" v-model="data.tgl_jurnal"></flat-pickr>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Description</label>
            <vs-input class="w-full" v-model="data.keterangan"/>
          </div>
        </div>

        <vs-divider class="mt-base">Detail Jurnal</vs-divider>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="flex flex-wrap justify-between items-center mb-1">
              <div class="mb-4 md:mb-0 mr-4">
                <div class="flex">
                  <vs-button color="success" icon-pack="feather" icon="icon-plus" @click="addRow" class="py-2 px-3 mr-2">Add</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <vs-table :data="data.details" stripe>
          <template slot="thead">
            <vs-th class="whitespace-no-wrap">#</vs-th>
            <vs-th class="whitespace-no-wrap">Account Code *</vs-th>
            <vs-th class="whitespace-no-wrap">Account Name *</vs-th>
            <vs-th class="whitespace-no-wrap">Description *</vs-th>
            <vs-th class="whitespace-no-wrap">Debit</vs-th>
            <vs-th class="whitespace-no-wrap">Credit</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
              <vs-td>
                <vs-button color="danger" class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-x" size="small" @click="removeRow(item.uuid)"/>
              </vs-td>
              <vs-td>
                <div class="w-48">
                  <div @click="chooseCoa(item.uuid)">
                    <vx-input-group>
                      <vs-input placeholder="Account Code" :value="item.kode_coa" readonly/>
                      <template slot="append">
                        <div class="append-text btn-addon">
                          <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                        </div>
                      </template>
                    </vx-input-group>
                  </div>
                </div>
              </vs-td>
              <vs-td><vs-input class="w-64" type="text" v-model="item.nama_coa" readonly/></vs-td>
              <vs-td><vs-input class="w-52" type="text" v-model="item.keterangan"/></vs-td>
              <vs-td><v-money class="w-48" type="text" v-model="item.debet"/></vs-td>
              <vs-td><v-money class="w-48" type="text" v-model="item.kredit"/></vs-td>
            </vs-tr>

            <!--footer-->
            <vs-tr v-if="data.length > 0">
              <vs-td class="text-right"></vs-td>
              <vs-td class="text-right"></vs-td>
              <vs-td class="text-right"></vs-td>
              <vs-td class="text-right"></vs-td>
              <vs-td class="text-left">{{ summary.grandTotalDebet | idr }}</vs-td>
              <vs-td class="text-left">{{ summary.grandTotalKredit | idr }}</vs-td>
            </vs-tr>

          </template>
        </vs-table>

        <div class="vx-row mt-6">
          <div class="vx-col w-full sm:w-12/12">
            <div class="flex items-center justify-end">
              <div class="flex space-x-3">
                <vs-button type="border" @click="emitModalIsActive(false)">Batal</vs-button>
                <vs-button :disabled="isLoading" @click="save">
                  <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
                  <span v-if="!isLoading">Simpan</span>
                </vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--modal coa-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih COA"
                :active="modalCoa.active"
                v-on:update:active="modalCoa.active = $event">
        <Coa :selectable="true" :externalFilter="filterCoa" @selected="onSelectedModalCoa"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import GeneralJurnalRepository from '@/repositories/accounting/general-jurnal-repository'
import ProyekRepository from '@/repositories/master/proyek-repository'
import Coa from '@/views/pages/master/coa/Coa'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import VMoney from '@/views/components/v-money/VMoney'
import vSelect from 'vue-select'
import _ from 'lodash'
import moment from 'moment'
import { v4 as uuid } from 'uuid'

export default {
  name: 'GeneralJurnalAdd',
  props: ['isActive', 'idProyek'],
  components: {
    ValidationErrors,
    flatPickr,
    Coa,
    VMoney,
    'v-select': vSelect
  },
  mounted () {
    this.getAllProyek()
  },
  watch: {
    isActive (active) {
      if (active) {
        this.data.id_proyek = this.idProyek
      }
    }
  },
  data () {
    return {
      isLoading: false,
      errors: null,
      modalCoa: {
        rowUuid: null,
        active: false
      },
      data: {
        id_proyek: null,
        tgl_jurnal: moment().format('YYYY-MM-DD'),
        details: []
      },
      proyeks: []
    }
  },
  computed: {
    summary () {
      return {
        grandTotalDebet: _.sumBy(this.data.details, item => parseFloat(item.debet || '0')),
        grandTotalKredit: _.sumBy(this.data.details, item => parseFloat(item.kredit || '0'))
      }
    },
    filterCoa () {
      return {
        tipe: 'DETAIL',
        id_proyek: this.data.id_proyek
      }
    }
  },
  methods: {
    getAllProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      GeneralJurnalRepository.create(this.data)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    chooseCoa (rowUuid) {
      this.modalCoa.rowUuid = rowUuid
      this.modalCoa.active = true
    },

    onSelectedModalCoa (data) {
      const uuid = this.modalCoa.rowUuid
      const index = _.findIndex(this.data.details, item => item.uuid === uuid)
      this.data.details[index].id_coa = data.id
      this.data.details[index].kode_coa = data.kode
      this.data.details[index].nama_coa = data.nama
      this.modalCoa.active = false
    },

    addRow () {
      const row = {
        uuid: uuid(),
        debet: 0,
        kredit: 0
      }
      this.data.details.push(row)
    },

    removeRow (uuid) {
      const index = _.findIndex(this.data.details, item => item.uuid === uuid)
      this.data.details.splice(index, 1)
    },

    onChangeProyek () {
      if (this.data.details.length > 0) {
        this.clearRows()
      }
    },

    clearRows () {
      this.$vs.dialog({
        ...this.confirmOptionsClearRows(),
        accept: () => {
          this.data.details = []
        }
      })
    },

    confirmOptionsClearRows () {
      return {
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Semua data Detail Jurnal COA akan dihapus?',
        acceptText: 'Ya',
        cancelText: 'Batal'
      }
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['proyeks'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
